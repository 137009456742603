@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@ng-select/ng-select/themes/default.theme.css';

.css-tooltip .css-tooltiptext {
  visibility: hidden;
  display: block;
  /* background-color: #c0c0c0; */
  background-color: #ebf5fb;
  font-size: 16px;
  color: black;
  text-align: left;
  border-radius: 6px;
  padding: 5px 0;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  text-decoration: none;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.example-h2 {
  margin: 10px;
}

.underline {
  text-decoration: underline;
}

.example-section {
  display: flex;
  align-content: left;
  align-items: left;
  height: 60px;
}

.example-margin {
  margin: 0 10px;
}

.ucd-link {
  text-decoration: none;
  font-weight: normal;
  color: #073376;
  cursor: pointer;
}

.ucd-header1 {
  color: #033266;
}

.ucd-subheader1 {
  color: #13639e;
  font-weight: 500;
  font-size: 18px;
}

.email-link {
  color: #062a4c;
  font-weight: 500;
  cursor: pointer;
}

.phone-link {
  color: #06874c;
  font-weight: 500;
  cursor: pointer;
}

.title {
  font-size: 20px;
  font-weight: 400;
}

.smooth-border {
  border-radius: 10px;
  border: 1px solid gray;
  overflow: hidden;
}

.or-required {
  color: #800000;
}

.or-black {
  color: #000;
}

.cursorPointer {
  cursor: pointer;
}

.css-tooltip-more:hover .css-tooltiptext {
  visibility: visible;
}

.css-description {
  width: 500px;
}

.italic {
  font-style: italic;
}

.font-weight-500 {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.font-weight-350 {
  font-weight: 350;
}

.font-weight-425 {
  font-weight: 425;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

input[type='checkbox'] {
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}

label {
  display: block;
}

.padding-0 {
  padding: 0px;
}

.padding-4 {
  padding: 4px;
}

.padding-6 {
  padding: 6px;
}

.padding-top4 {
  padding-top: 4px;
}

.padding-top7 {
  padding-top: 7px;
}

.padding-top10 {
  padding-top: 10px;
}

.padding-top15 {
  padding-top: 15px;
}

.padding-right0 {
  padding-right: 0px;
}

.padding-right3 {
  padding-right: 3px;
}

.padding-right5 {
  padding-right: 5px;
}

.padding-right8 {
  padding-right: 8px;
}

.padding-right10 {
  padding-right: 10px;
}

.padding-right15 {
  padding-right: 15px;
}

.padding-right20 {
  padding-right: 20px;
}

.padding-left0 {
  padding-left: 0px;
}

.padding-left5 {
  padding-left: 5px;
}

.padding-left6 {
  padding-left: 6px;
}

.padding-left10 {
  padding-left: 10px;
}

.padding-left15 {
  padding-left: 15px;
}

.padding-left20 {
  padding-left: 15px;
}

.padding-bottom5 {
  padding-bottom: 5px;
}

.padding-bottom10 {
  padding-bottom: 10px;
}

.padding-2-4 {
  padding: 2px 4px;
}

.padding-3-6 {
  padding: 3px 6px;
}

.padding-4-8 {
  padding: 4px 8px;
}

.margin-right5 {
  margin-right: 5px;
}

.margin0 {
  margin: 0;
}

.margin-top0 {
  margin-top: 0;
}

.margin-top3 {
  margin-top: 3px;
}

.margin-top5 {
  margin-top: 5px;
}

.margin-top7 {
  margin-top: 7px;
}

.margin-top10 {
  margin-top: 10px;
}

.margin-top15 {
  margin-top: 15px;
}

.margin-top20 {
  margin-top: 20px;
}

.margin-top25 {
  margin-top: 25px;
}

.margin-top30 {
  margin-top: 30px;
}

.margin-top35 {
  margin-top: 30px;
}

.margin-top40 {
  margin-top: 40px;
}

.margin-top50 {
  margin-top: 50px;
}

.margin-bottom0 {
  margin-bottom: 0;
}

.margin-bottom5 {
  margin-bottom: 5px;
}

.margin-bottom10 {
  margin-bottom: 10px;
}

.margin-bottom15 {
  margin-bottom: 15px;
}

.margin-bottom20 {
  margin-bottom: 20px;
}

.margin-bottom25 {
  margin-bottom: 25px;
}

.margin-bottom30 {
  margin-bottom: 30px;
}

.margin-bottom35 {
  margin-bottom: 35px;
}

.margin-bottom40 {
  margin-bottom: 40px;
}

.margin-bottom60 {
  margin-bottom: 60px;
}

.margin-leftMinus10 {
  margin-left: -15px;
}

.margin-left0 {
  margin-left: 0;
}

.margin-left2 {
  margin-left: 2px;
}

.margin-left4 {
  margin-left: 4px;
}

.margin-left5 {
  margin-left: 5px;
}

.margin-left10 {
  margin-left: 10px;
}

.margin-left15 {
  margin-left: 15px;
}

.margin-left20 {
  margin-left: 20px;
}

.margin-left25 {
  margin-left: 25px;
}

.margin-left30 {
  margin-left: 30px;
}

.margin-left40 {
  margin-left: 40px;
}

.margin-left44 {
  margin-left: 44px;
}

.margin-left50 {
  margin-left: 50px;
}

.margin-left70 {
  margin-left: 70px;
}

.margin-right5 {
  margin-right: 5px;
}

.margin-right10 {
  margin-right: 10px;
}

.margin-right15 {
  margin-right: 15px;
}

.margin-right20 {
  margin-right: 20px;
}

.date-picker .mat-calendar-period-button {
  pointer-events: none;
}

.date-picker .mat-calendar-arrow {
  display: none;
}

.display-hide {
  display: none;
}

.display-block {
  display: block;
}

.mat-mdc-text-field-wrapper {
  height: 40px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  height: 40px !important;
}

/* flex box */

.box {
  display: flex;
  flex-flow: row wrap;
}

.select-all-container {
  margin-top: 5px;
}

.button-pill {
  background-color: #ddd;
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
}

/* .page-link {
  color: #0000a0!important;
  background: #fff !important;
} */

.page-item.active .page-link {
  color: #fff !important;
  background: #0000a0 !important;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: #0000a0 !important;
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#img img {
  display: block;
  margin: 0 auto;
  width: 218px;
  height: 85px;
}

.or-info {
  background-color: #000;
}

.bg-override-dark {
  background-color: #022851;
}
